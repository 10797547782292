import React,{useState,useEffect,useRef} from 'react';
import { useParams } from 'react-router-dom';
import './AirportBusTime.css';
import airportBusImage from '../../../images/airport_bus-01.png';
import { ROUTE_DATA,ROUTE_NAME,BOOKING_COUNTER } from '../../../lib/kyc-centers';

const AirportBusTime = () => {
    const [BusTime , setBusTime ] = useState();
    const [currentTime, setCurrentTime] = useState('');
    const [currentEpochTime , setCurrentEpochTime] = useState();
    const [AMPM , setAMPM] = useState();
    const APITimeResponseInterval = useRef();
    const { terminalId } = useParams();

    const [responseData, setResponseData] = useState(null);
    const postData = () => {
      fetch(`${process.env.REACT_APP_API_PATH}/tub/v1/trips/mumbai/by_route_stop_pairs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJwcmVwcm9kL3Byb2QiLCJhcGkiOiJieV9yb3V0ZV9zdG9wX3BhaXJzIiwic2VydmljZSI6InR1YiJ9.3Xrqiuc5b48e0rKCAjdgpnk43GruplFiEhJXGXe-GV4",
        },
        body: JSON.stringify({...ROUTE_DATA?.[terminalId],timeWindow: {fromTime: Date.now().toString(),toTime: (Date.now()+43200000).toString()}}),
      }).then(async (response)=>{
        if (response.ok) {
          const json = await response.json();
          setResponseData(json);
        } else {
          console.error('Error:', response.statusText);
        }
      }
      ).catch((error)=>{
        console.error('Error:', error);
      });

      if(APITimeResponseInterval.current){
        clearInterval(APITimeResponseInterval.current);
      }
      
      APITimeResponseInterval.current = setInterval(() => {
        fetch(`${process.env.REACT_APP_API_PATH}/tub/v1/trips/mumbai/by_route_stop_pairs`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbnYiOiJwcmVwcm9kL3Byb2QiLCJhcGkiOiJieV9yb3V0ZV9zdG9wX3BhaXJzIiwic2VydmljZSI6InR1YiJ9.3Xrqiuc5b48e0rKCAjdgpnk43GruplFiEhJXGXe-GV4",
          },
          body: JSON.stringify({...ROUTE_DATA?.[terminalId],timeWindow: {fromTime: Date.now().toString(),toTime: (Date.now()+43200000).toString()}}),
        }).then(async (response)=>{
          if (response.ok) {
            const json = await response.json();
            setResponseData(json);
          } else {
            console.error('Error:', response.statusText);
          }
        }
        ).catch((error)=>{
          console.error('Error:', error);
        });
      }, 30000);
  };

    useEffect(()=>{
        postData();
        return ()=>{
          clearInterval(APITimeResponseInterval.current);
        }
    },[]);

    useEffect(() => {
    function updateTime() {
      const now = new Date();
      setCurrentEpochTime(now.getTime());
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const formattedTime = `${hours === 12 ? '12': hours%12}:${minutes < 10 ? '0' : ''}${minutes}`;
      if(hours >= 12){
        setAMPM('PM');
      }else{
        setAMPM('AM');
      }
      setCurrentTime(formattedTime);
    }

    updateTime();
    const intervalId = setInterval(updateTime, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);


  return (
    <div className='box'>
    <div className='container'>
        <div className='upper-section'>
            <div className='heading'> LIVE SCHEDULE </div> 
            <div className='sub-heading'> NEXT BUS TO </div>
            <div className='busstop-list'>
                {responseData?.data?.map((element) => (
                    <div className='bus-stop'>
                        <div className='stop stop-name'>{ROUTE_NAME.routeName.find(route => route.routeId === element?.routeId)?.stopName}</div>
                        {element?.timings?.length ? (
                        <div className='stop stop-time'>{Math.round((((Math.min(...element.timings)) - currentEpochTime) / 60000) / 60) >0
                          ? `${Math.round((((Math.min(...element.timings)) - currentEpochTime) / 60000) / 60)} hr `
                          : ''}
                         {Math.round((( (Math.min(...element.timings))-(currentEpochTime))/60000 )%60)} min</div>
                         ):<div className='stop stop-time'>Not Available</div>}
                    </div>
                ))}
            </div>
            <div className='current-time'>CURRENT TIME : {currentTime} {AMPM}</div>

        </div>
        <div className='lower-section'>
            <div className='description'>
            <span className='book'>BOOK AT </span> ARRIVAL HALL
            <div className='counter'>{BOOKING_COUNTER?.[terminalId]}</div>
            </div>
        </div>
    </div>
     <div className='bus-section'>
     <img src={airportBusImage} alt='airportbus'/>
    </div>
 </div>
  )
}

export default AirportBusTime;