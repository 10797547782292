import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import KYCCenters from "./pages/KYCCenters";
// chalo/fullkyc-centers
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import AirportBusTime from './pages/AirportStaticScreens/AirportChaloBusTime';
import TermsAndConditions from './pages/CrutApp/TermsAndConditions';
// import AirportDirection from './pages/AirportStaticScreens/AirportDirection';

const BestPage = React.lazy(() => import("./pages/BestPage"));
const ChaloPay = React.lazy(() => import("./pages/ChaloPay"));
// import BestPage from "./pages/BestPage";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/app/chalo/best/routeupdates" replace={true} />} /> 
          <Route path="/app/chalo/best/routeupdates" element={<BestPage />} />
          <Route path="/app/chalo/chalo-pay/promotions/promote-chalo-pay" element={<ChaloPay />} />
          <Route path="/app/chalo/fullkyc-centers" element={<KYCCenters />} />
          <Route path="/app/chalo/airport-static-screens/airport-chalo-bus-time/:terminalId" element={<AirportBusTime/>} />
          <Route path="/app/crut/privacy-policy" element={<TermsAndConditions/>} />
          {/* <Route path="/app/chalo/airport-static-screens/airport-direction/:terminalId" element={<AirportDirection/>} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
