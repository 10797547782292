import React from 'react'
import './termsAndConditions.css';

const TermsAndConditions = () => {
  const items = [
    'Provide and improve the App;',
    'Build a profile of your preferences so we can personalise the App and provide you with relevant transport information and recommendations;', 
    'Verify your identity and administer your Account, if you have one;',
    'Process or facilitate payment (through third party payment processing services);',
    'Send you marketing communications or other forms of communications through several channels;',
    'Identify issues with the operation of App in order to find resolutions for these;',
    'Carry out research on users’ transport choices to improve App and also work with transport providers to improve transport services',
    'Disclose to lawful authorities when required to do so by law, or when appropriate, in our opinion, to respond to their request.;'
];
  return (
    <div className='container'>
        <div className='tc-header'>Privacy Policy</div>

        <div className='box-header'>
        <div className='box-header-heading'>Privacy Policy</div>
        <div className='box-header-description'>Your access to and use of the App is also subject to our Privacy Policy. By accepting these Terms, you confirm that you have reviewed and understand our Privacy Policy, and you acknowledge that any information shared by, or collected from or about you, may be used by CRUT in accordance with our Privacy Policy, as it is updated or changed from time to time.</div>
        </div>

        <div className='box-header'>
        <div className='box-header-heading'>There are three types of information we process (‘Information’) :</div>
        <div className='box-header-description'>Information you provide – such as your personal information when you create a CRUT Account, your searches, places, preferences, direction communications, etc.</div>
        <div className='box-header-description'>Information created by your use of the App – such as location information, device information, usage information, travel patterns, etc.</div>
        <div className='box-header-description'>Information from other sources – such as trip information, transport provider information, etc.</div>
        <div className='box-header-description'>Any Information collected by us is collected in a lawful manner, is directly connected with a function or feature offered on the App and is considered necessary for that purpose.</div>
        </div>

        <div className='box-header'>
        <div className='box-header-heading'>We use Information to:</div>
        <ul className='list-container'>
                {items.map((item, index) => (
                    <li key={index} className='box-header-description-list'>{item}</li>
                ))}
        </ul>
        <div className='box-header-description'>From time to time we may also disclose Information with third parties. This disclosure may be required for us to provide you access to our products and services; for enhancing your experience; for providing feedback on products; for fulfilment of your purchases; to collect payments from you; to comply with our legal obligations; to conduct market research or surveys; to enforce our Terms; to facilitate our marketing and advertising activities; to analyse data; for customer service assistance; to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our product and services; or for other purposes. We do not disclose your personal information to third parties for their marketing and advertising purposes without your explicit consent.</div>
        <div className='box-header-description'>We and our partners will share / sell some or all of Information with another business entity should we (or our assets) plan to merge with, or be acquired by that business entity, or re-organisation, amalgamation, or restructuring of business. Should such a transaction occur that other business entity (or the new combined entity) will be required to follow this Privacy Policy with respect to Information.</div>
        <div className='box-header-description'>We do not knowingly solicit or collect Information from people under the age of 18 (‘Minor’). If you are a Minor, you represent and agree that you have the legal consent of your parent or guardian to access and use the App.</div>
        <div className='box-header-description'>If you disclose to us any Information relating to other people (‘Others’ Information’), you represent that you have the authority to do so and to permit us to use such Others’ Information in accordance with these Terms.</div>
        <div className='box-header-description'>You, while providing Information to us or any of our partners, including our other corporate entities, affiliates, lending partners, technology partners, marketing channels, business partners and other third parties, to contact you through any mode of communication for the purposes specified in these Terms.</div>
        </div>
        <div className='box-header box-header-bottom'>
        <div className='box-header-heading'>Grievance contact:</div>
        <div className='box-header-description'>Capital Region Urban Transport</div>
        <div className='box-header-description-list'>Email - crutapps@gmail.com</div>
        </div>
    </div>
  )
}

export default TermsAndConditions