const  KYC_CENTER=JSON.parse(process.env.REACT_APP_KYC_CENTERS)
const  ROUTE_DATA= JSON.parse(process.env.REACT_APP_STATIC_SCREEN_ROUTE_DATA);
const  ROUTE_NAME = JSON.parse(process.env.REACT_APP_STATIC_SCREEN_ROUTE_NAME);
const BOOKING_COUNTER = JSON.parse(process.env.REACT_APP_BOOKING_COUNTER_PLACE);

export{
    KYC_CENTER,
    ROUTE_DATA,
    ROUTE_NAME,
    BOOKING_COUNTER,
}